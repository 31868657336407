import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1 {...{
      "id": "blackbox-components-with-hooks"
    }}>{`Blackbox components with hooks`}</h1>
    <p>{`Blackbox components wrap any D3 code in React. They're called blackbox because React can't see inside and can't help you with rendering`}</p>
    <p>{`Perfect for small components like axes (which are tedious to build yourself) and`}</p>
    <p>{`Use this D3 code for an axis to create a simple `}<inlineCode parentName="p">{`<Axis>`}</inlineCode>{` component.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`const scale = d3.scaleLinear().domain([0, 10]).range([0, 200])
const axis = d3.axisBottom(scale)
`}</code></pre>
    <p>{`You'll need the `}<inlineCode parentName="p">{`useRef`}</inlineCode>{` hook to reference a rendered `}<inlineCode parentName="p">{`<g>`}</inlineCode>{` element and the `}<inlineCode parentName="p">{`useEffect`}</inlineCode>{` hook to trigger D3 rendering when the component changes.`}</p>
    <iframe {...{
      "src": "https://codesandbox.io/embed/react-d3-axis-unsolved-c2o79?file=/src/Axis.js",
      "style": {
        "width": "100%",
        "height": "500px",
        "border": "0",
        "borderRadius": "4px",
        "overflow": "hidden"
      },
      "allow": "accelerometer; ambient-light-sensor; camera; encrypted-media; geolocation; gyroscope; hid; microphone; midi; payment; usb; vr; xr-spatial-tracking",
      "sandbox": "allow-forms allow-modals allow-popups allow-presentation allow-same-origin allow-scripts"
    }}></iframe>
    <p>{`Try solving it without looking at my solution.`}</p>
    <h2 {...{
      "id": "my-solution"
    }}>{`My solution`}</h2>
    <iframe {...{
      "src": "https://codesandbox.io/embed/react-d3-axis-hooks-df304",
      "style": {
        "width": "100%",
        "height": "500px",
        "border": "0",
        "borderRadius": "4px",
        "overflow": "hidden"
      },
      "allow": "accelerometer; ambient-light-sensor; camera; encrypted-media; geolocation; gyroscope; hid; microphone; midi; payment; usb; vr; xr-spatial-tracking",
      "sandbox": "allow-forms allow-modals allow-popups allow-presentation allow-same-origin allow-scripts"
    }}></iframe>
    <h2 {...{
      "id": "my-used3-hook-for-blackbox-components"
    }}>{`my useD3 hook for blackbox components`}</h2>
    <p>{`Even better than doing it yourself is using the ready-made `}<inlineCode parentName="p">{`useD3`}</inlineCode>{` hook I opensourced for you :)`}</p>
    <p>{`Read the full docs at `}<a parentName="p" {...{
        "href": "https://d3blackbox.com/"
      }}>{`d3blackbox.com`}</a></p>
    <p>{`It works as a combination of `}<inlineCode parentName="p">{`useRef`}</inlineCode>{` and `}<inlineCode parentName="p">{`useEffect`}</inlineCode>{`. Hooks into component
re-renders, gives you control of the anchor element, and re-runs your D3 render
function on every component render.`}</p>
    <p>{`You use it like this:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`import { useD3 } from "d3blackbox"
function renderSomeD3(anchor) {
  d3.select(anchor)
  // ...
}

const MyD3Component = ({ x, y }) => {
  const refAnchor = useD3((anchor) => renderSomeD3(anchor))
  return <g ref={refAnchor} transform={\`translate(\${x}, \${y})\`} />
}
`}</code></pre>
    <p>{`You'll see how this works in more detail when we build the big example project. We'll use `}<inlineCode parentName="p">{`useD3`}</inlineCode>{` to build axes.`}</p>
    <p>{`Here's how the above example looks when you use `}<inlineCode parentName="p">{`useD3`}</inlineCode>{` ✌️`}</p>
    <h1 {...{
      "id": "exercise"
    }}>{`Exercise`}</h1>
    <p>{`Now try using the `}<inlineCode parentName="p">{`useD3`}</inlineCode>{` hook to create a barchart component based on the earlier D3 example.`}</p>
    <iframe src="https://cdn.rawgit.com/mbostock/3885304/raw/a91f37f5f4b43269df3dbabcda0090310c05285d/index.html" width="100%" height="480" style={{
      "border": "0px",
      "overflow": "hidden"
    }}></iframe>
    <p>{`You can get the code by right-click + view source `}<a parentName="p" {...{
        "href": "https://cdn.rawgit.com/mbostock/3885304/raw/a91f37f5f4b43269df3dbabcda0090310c05285d/index.html"
      }}>{`on this link here`}</a></p>
    <p>{`This example was written with d3v4 so you'll have to change `}<inlineCode parentName="p">{`d3.tsv()`}</inlineCode>{` to return `}<inlineCode parentName="p">{`data`}</inlineCode>{` as a promise. I've prepared the dataset and styling for you :)`}</p>
    <iframe {...{
      "src": "https://codesandbox.io/embed/react-d3-blackbox-barchart-unsolved-gq0su",
      "style": {
        "width": "100%",
        "height": "500px",
        "border": "0",
        "borderRadius": "4px",
        "overflow": "hidden"
      },
      "allow": "accelerometer; ambient-light-sensor; camera; encrypted-media; geolocation; gyroscope; hid; microphone; midi; payment; usb; vr; xr-spatial-tracking",
      "sandbox": "allow-forms allow-modals allow-popups allow-presentation allow-same-origin allow-scripts"
    }}></iframe>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      